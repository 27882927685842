export const initAdjustEvent = async (mode) => {
  !(function (t, a, e, r, n, s, d, l, o, i, u) {
    ;(t.Adjust = t.Adjust || {}), (t.Adjust_q = t.Adjust_q || [])
    for (let c = 0; c < l.length; c++) o(t.Adjust, t.Adjust_q, l[c])
    ;(i = a.createElement(e)),
    (u = a.getElementsByTagName(e)[0]),
    (i.async = !0),
    (i.src = 'https://cdn.adjust.com/adjust-latest.min.js'),
    (i.onload = function () {
      for (let a = 0; a < t.Adjust_q.length; a++) {
        t.Adjust[t.Adjust_q[a][0]].apply(t.Adjust, t.Adjust_q[a][1])
      }
      t.Adjust_q = []
    }),
    u.parentNode.insertBefore(i, u)
  })(
    window,
    document,
    'script',
    0,
    0,
    0,
    0,
    [
      'initSdk',
      'getAttribution',
      'getWebUUID',
      'trackEvent',
      'addGlobalCallbackParameters',
      'addGlobalPartnerParameters',
      'removeGlobalCallbackParameter',
      'removeGlobalPartnerParameter',
      'clearGlobalCallbackParameters',
      'clearGlobalPartnerParameters',
      'switchToOfflineMode',
      'switchBackToOnlineMode',
      'stop',
      'restart',
      'gdprForgetMe',
      'disableThirdPartySharing',
      'initSmartBanner',
      'showSmartBanner',
      'hideSmartBanner'
    ],
    function (t, a, e) {
      t[e] = function () {
        a.push([e, arguments])
      }
    }
  )

  Adjust.initSdk({
    appToken: 'gg3e8sf62l8g',
    environment: mode
  })
}

export const trackAdjustEvent = (event, payload = {}) => {
  Adjust.trackEvent({
    eventToken: eventTokenList[event],
    ...payload
  })
}

const eventTokenList = {
  Login: 'g98nny',
  Added_To_Watchlist: 'x9dng6',
  REMOVED_FROM_WATCHLIST: '4qyuqs',
  VIEW_PLANS: 'cmjuvy',
  LOG_OUT: 'dsyjgf',
  SEARCHED_: '4m678e_',
  SUBSCRIPTION_FAILED: '3sacu1',
  SUBSCRIPTION_COMPLETED: '526fv1',
  SignedUp_: 'ry3hxk',
  PAGE_VIEWED: '7y4t18',
  ADD_TO_CART: 'cyoxq5',
  PLAY_STARTED: 'pane9o',
  WATCHED: '3gyl2i',
  FAILED_SEARCH: '9xa8dy',
  FILM_VIEWING: 'k29v7i',
  Subscription_Initiated: 'k4lvnr',
  Play_Back_Seek_Completed: '2idws8',
  Rewind_: '4cui94',
  Skip_: 'xygppp'
}

export const ADJUST_EVENTS = {
  REMOVED_FROM_WATCHLIST: 'REMOVED_FROM_WATCHLIST',
  PLAY_STARTED: 'PLAY_STARTED',
  VIEW_PLANS: 'VIEW_PLANS',
  REGISTRATION_COMPLETE: 'Registration Complete',
  SUBSCRIPTION_COMPLETED: 'SUBSCRIPTION_COMPLETED',
  LOGIN: 'Login',
  ADDED_TO_WATCHLIST: 'Added_To_Watchlist',
  SEARCHED: 'SEARCHED_',
  LOGOUT: 'LOG_OUT',
  WATCHED: 'WATCHED',
  ADD_TO_CART: 'ADD_TO_CART',
  SUBSCRIPTION_FAILED: 'SUBSCRIPTION_FAILED',
  SUBSCRIPTION_INITIATED: 'Subscription_Initiated',
  PAGE_VIEWED: 'PAGE_VIEWED',
  FAILED_SEARCH: 'FAILED_SEARCH',
  REGISTERED_BUT_NOT_SUBSCRIBED: 'Registered But Not Subscribed',
  FILM_VIEWING: 'FILM_VIEWING',
  REGISTRATION: 'Registration',
  SignedUp_: 'SignedUp_',
  Play_Back_Seek_Completed: 'Play_Back_Seek_Completed',
  Rewind_: 'Rewind_',
  Skip_: 'Skip_'
}

// REGISTERED_BUT_NOT_SUBSCRIBED: 'Registered But Not Subscribed', ;left

// REGISTRATION_COMPLETE: 'Registration Complete',left
// REGISTRATION: 'Registration', left

// export default initAdjustEvent;
