import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import withRouter from './utils/withRouter'

import { getToken, clearToken, setToken } from '~/client/tools/tokens'
import LazyLoadModules from './LazyLoadModules'
import { getCachePageData, setMWeb } from './redux/actions/pageActions'

import Loader from './components/Loader'
import config from '../../config'
import {
  getWatchlist,
  addToWatchlist,
  removeFromWatchlist
} from './redux/actions/userRestActions'
import { logout } from '~/client/redux/actions/userAuthActions'
import { setPopup } from './redux/actions/popupsActions'
import {
  addToUser,
  fetchHistory,
  updateUserDetailsWithoutApi
} from './redux/actions/userEditActions'
import initializeListenner from './tools/initializeListenner'
import Notifier from './popups/Notifier'
import { NOTIFIER } from './redux/popup-types'
import loadable from '@loadable/component'
import { MetaTags } from './components/MetaTags'

import {
  initCleverTap,
  trackCleverTapEvent,
  CLEVERTAP_EVENTS
} from './tools/clevertap'
import setUTM from '~/client/tools/utm'

import { getPageSettingsData } from '~/client/redux/actions/pageSettingsAction'
import CommonPopup from './popups/CommonPopup'
import { checkToken } from './apis/checkToken'

import {
  initAdjustEvent,
  trackAdjustEvent,
  ADJUST_EVENTS
} from './tools/adjustInIt'
import TopBanner from './components/TopBanner'

import {
  initGtmAdwords,
  trackPageViewGa4,
  trackPageViewEvent
} from './tools/googleAnalytics'

import {
  initFbEvent,
  trackFbPixel,
  trackFbEvent,
  pixelEvents,
  FB_EVENTS
} from './tools/fbInit'
import { VIDEOPLAYER } from '~/client/redux/popup-types'
import {
  fetchSeriesWatchHistory,
  fetchVideoDetails,
  getVideoInfo
} from '~/client/apis/videoPlayer'
import { removeNextEpisodeId } from './redux/actions/nextVideoActions'
import InstallerBanner from './components/InstallerBanner'
import { Route, Routes, useLocation } from 'react-router-dom'
import ResetPassword from './components/ResetPassword'
import ThankYou from './components/ThankYou'

import { addgraminUserDetails } from './redux/actions/graminUserAction'
import { verifyToken } from './utils/tokenVerification'
import axios from 'axios'
import { differenceInDays, isAfter, isBefore } from 'date-fns'
import FloatingButton from './components/FloatingButton/FloatingButton'
import { Toaster } from 'sonner'
import Cookies from 'js-cookie'
import useDisableDevTools from './hooks/useDisableDevtools'

const defaultBannerDetails = {
  text: '',
  type: '',
  to: '',
  centerCheck: false
}

const App = ({
  path,
  layout,
  contentData = '',
  location,
  getCachePageData,
  navigate,
  user,
  mweb,
  graminUser,
  setMWeb,
  getWatchlist,
  setPopup,
  addToWatchlist,
  removeFromWatchlist,
  addToUser,
  notifier,
  videoplayer,
  getPageSettingsData,
  commonpopup,
  logout,
  login,
  nextVideo,
  removeNextEpisodeId,
  pageSettings,
  updateUserDetailsWithoutApi,
  addgraminUserDetails
}) => {
  // const [components, setComponents] = useState([]);

  const [showCommonPopup, setShowCommonPopup] = useState(false)
  const [tokenVerificationPending, setTokenVerificationPending] = useState(true)
  const isVideoPlayerRef = useRef(null)
  isVideoPlayerRef.current = videoplayer
  const watchedDetailsRef = useRef(null)
  const seriesWatchHistoryRef = useRef(null)
  const VideoPlayer = loadable(() =>
    import(/* webpackPreload: true */ './components/VideoPlayer')
  )
  const AddPhone = loadable(() => import('../client/popups/AddPhone/index'))
  const NoInternet = loadable(() => import('../client/popups/NoInternet/index'))

  const LoginComponent = loadable(() => import('./popups/OTPLogin'))
  const locationPath = useLocation()
  const urlParams = new URLSearchParams(locationPath.search)
  const token = urlParams.get('token')
  const [showTopBanner, setTopBanner] = useState(false)
  const [showAddPhoneScreen, setShowAddPhoneScreen] = useState(false)
  const [isDisconnected, setIsdisconnected] = useState(false)
  const [closeInternetPop, setCloseInternetPop] = useState(false)
  const checkInternetTimerId = useRef()
  const [bannerDetails, setBannerDetails] = useState(defaultBannerDetails)
  const [browserName, setBrowserName] = useState('')

  const isIndia = Cookies.get('hc-cc') === 'IN'

  useDisableDevTools()

  useEffect(() => {
    if (window?._MODE === 'production') {
      // loadSalesForceChatBot();

      initAdjustEvent('production').catch((err) => {
        console.log(err)
      })
    } else {
      initAdjustEvent('sandbox').catch((err) => {
        console.log(err)
      })
    }
    if (window) {
      initCleverTap()
      initFbEvent()
      // initGtmAdwords()
    }
    setUTM()
    getToken()
    if (
      Boolean(pageSettings) === false ||
      Object.keys(pageSettings).length === 0
    ) {
      getPageSettingsData()
    }
    // const smoothscroll = require('smoothscroll-polyfill');
    // smoothscroll.polyfill();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', function (event) {
        // do stuff here

        // mweb === false
        // const breakPoint = 425

        if (window.screen.width <= 500) {
          // update mweb redux state
          setMWeb(true)
        }
        // to check the browser
        if (window?.navigator.userAgent.match(/safari/i)) {
          setBrowserName('Safari')
        } else {
          setBrowserName('')
        }
        // mweb == false;

        // 428 x 746 dp
        if (
          window.screen.width > 500 &&
          window?.screen?.orientation?.angle === 0
        ) {
          setMWeb(false)
        }
      })
    }

    localStorage.removeItem('paymentHandler')
    localStorage.removeItem('order')

    const handleInfoIconClick = function (e) {
      const icon = e?.target?.attributes

      const infoIcon = icon['data-info-icon']
      if (!infoIcon) return
      const { value } = infoIcon || {}

      if (value) {
        navigate(value)
      }
    }

    localStorage.removeItem('paymentHandler')
    localStorage.removeItem('order')

    document.addEventListener('click', handleInfoIconClick)
    // window.addEventListener('load', requestUserPermission);
    // const lazy = false

    return () => {
      document.removeEventListener('click', handleInfoIconClick)

      // window.removeEventListener('load', requestUserPermission);
    }
  }, [])

  useEffect(() => {
    if (!contentData || !layout) {
      getCachePageData(location.pathname)
    }
  }, [contentData, layout])

  useEffect(() => {
    // Define the start date (August 12th, 2024, 12:00 AM)
    const startDate = new Date('2024-08-12T00:00:00')

    // Define the end date (August 19th, 2024, 11:59 PM)
    const endDate = new Date('2024-08-19T23:59:00')

    // Check if a given date is within the interval
    const dateToCheck = new Date() // Replace this with the date you want to check

    const isWithin =
      isAfter(dateToCheck, startDate) && isBefore(dateToCheck, endDate)
    if (
      isWithin &&
      !user.isSubscribed &&
      locationPath.pathname !== '/subscribe' &&
      isIndia
    ) {
      setBannerDetails({
        text: !mweb ? (
          <p class="sale-price">
            Freedom Sale is live!!! Subscribe at just
            <span class="original-price">₹999</span> ₹799 🎉
          </p>
        ) : (
          'Freedom Sale is live 🎉'
        ),
        type: 'sub-ended',
        to: '/subscribe',
        centerCheck: true
      })

      setTopBanner(true)
    } else {
      setTopBanner(false)
    }
  }, [locationPath.pathname])

  const nav = () => {
    if (
      user.code === 'DEVICE_LIMIT_EXCEEDED'
      // location.pathname !== '/user'
    ) {
      setTimeout(() => {
        setBannerDetails({
          text: "You've surpassed your subscription's device limit. Please click on device management and remove the devices",
          type: 'device-exceed',
          to: '#'
        })
        if (!showTopBanner) setTopBanner(true)
      }, 2000)
      addToUser({ onUserTab: 5 })
      navigate('/user')
    } else {
      setTopBanner(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (user.auth) {
        nav()
      } else if (bannerDetails.type === 'device-exceed') {
        setBannerDetails(defaultBannerDetails)
      }
    }, 1000)
  }, [user.code, user.auth])

  useEffect(() => {
    if (user.auth) {
      setTimeout(() => {
        if (user?.phoneNumber) {
          setShowAddPhoneScreen(false)
        } else {
          const AddPhoneNumber = sessionStorage.getItem('AddPhoneNumber')
          if (AddPhoneNumber == 'true') {
            setShowAddPhoneScreen(false)
          } else if (user?.auth && user?.email && !user?.phoneNumber) {
            setShowAddPhoneScreen(true)
          }
        }
      }, 5000)
    }
  }, [user.auth, user.phoneNumber])

  useEffect(() => {
    // const {watchlist} = user
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const utm = urlParams.get('utm')
    if (utm === 'social') {
    }

    if (!mweb && !user.auth && location.pathname === '/user') {
      navigate('/')
    }
    // to redirect the loggedin unsubscribed user to the plan page
    if (
      !mweb &&
      user.auth &&
      !user.isSubscribed &&
      location.pathname !== '/redeem' &&
      location.pathname !== '/subscribe' &&
      location.pathname !== '/mid-week-offer'
    ) {
      navigate('/subscribe')
    }
  }, [user.auth])
  let userInfo

  let userData

  async function verifyUser(data) {
    clearToken()
    try {
      const userData = await verifyToken(data)
      if (userData === 'Request failed with status code 401') {
        return setShowCommonPopup({
          heading: 'Access Denied',
          subheading:
            'You have exceeds the maximum number of devices Please navigate to hoichoi.tv and deregister the other devices to enjoy the lattest content. ',
          ctaText: 'Try Again',
          onCtaClick: () => window.close(),
          onCloseClick: () => setShowCommonPopup(false)
        })
      }
      addgraminUserDetails(data)
      // if token is invalid
      if (userData) {
        userInfo = {
          authorizationToken: data,
          email: userData?.email,
          name: userData?.name,
          phoneCode: userData?.phoneCode,
          phoneNumber: userData?.phoneNumber,
          provider: userData?.provider,
          userId: userData?.userId,
          isSubscribed: userData?.isSubscribed
        }
        const modifiedUserData = { ...userInfo, auth: true }

        updateUserDetailsWithoutApi({ ...modifiedUserData })
        setTokenVerificationPending(false)
      }
    } catch (error) {
      console.error(error, 'error')
    }
  }

  useEffect(() => {
    if (!mweb && token) {
      return setShowCommonPopup({
        heading: 'Access Denied',
        subheading: 'Please try to access the website from mobile browser. ',
        ctaText: 'Contact The Support Team',
        onCtaClick: () => navigate('/'),
        onCloseClick: () => setShowCommonPopup(false)
      })
    }
    if (token) {
      verifyUser(token)
    }
    if (!tokenVerificationPending) {
      setToken({
        authorizationToken: graminUser,
        refreshToken: '',
        data: userInfo
      })
    }
  }, [token, mweb, user.auth, graminUser])

  useEffect(() => {
    const { subscription: { subscriptionStatus } = {} } = user
    if (user.auth && subscriptionStatus?.toUpperCase() === 'SUSPENDED') {
      setBannerDetails({
        text: 'Your Subscription ended, Click here to subscribe',
        type: 'sub-ended',
        to: '/subscribe',
        centerCheck: true
      })
      if (!showTopBanner) setTopBanner(true)
    } else if (
      user.auth &&
      user.subscriptionInfo &&
      user.subscriptionInfo.subscriptionEndDate &&
      subscriptionStatus === 'COMPLETED'
    ) {
      // const subscriptionEndDateInMs = new Date(
      //   user.subscriptionInfo.subscriptionEndDate
      // ).getTime()
      // const currentDateInMS = new Date().getTime()
      // const tenDaysInMs = 864000000
      // const daysLeftInMs = subscriptionEndDateInMs - currentDateInMS
      const daysLeft = differenceInDays(
        new Date(user.subscriptionInfo.subscriptionEndDate),
        new Date()
      )

      if (daysLeft <= 10 && daysLeft > 0) {
        setTimeout(() => {
          setBannerDetails({
            text: `Your Subscription is Expiring  in ${daysLeft} day(s)`,
            type: 'sub-ended',
            to: '/subscribe',
            centerCheck: true
          })
          if (!showTopBanner) setTopBanner(true)
        }, 5000)
      }
      // if (daysLeftInMs <= tenDaysInMs) {
      //   const days = ~~(daysLeftInMs / 1000 / 60 / 60 / 24)
      //   setTimeout(() => {
      //     setBannerDetails({
      //       text: `Your Subscription is Expiring  in ${
      //         days <= 0 ? '1 ' : days
      //       } day`,

      //       type: 'sub-ended',
      //       to: '/subscribe',
      //       centerCheck: true
      //     })
      //     if (!showTopBanner) setTopBanner(true)
      //   }, 5000)
      // }
    }
  }, [user?.subscription?.subscriptionStatus])

  useEffect(() => {
    if (
      user.auth &&
      location.pathname != '/user' &&
      location.pathname != '/subscribe'
    ) {
      getWatchlist(user)
    }
    let lastActivity = null
    if (document.referrer && document.referrer !== window.location.href) {
      lastActivity = document.referrer
    }

    const { id: pageId } = contentData

    if (pageId) {
      localStorage.setItem('pageId', pageId)
      setTimeout(() => {
        const payloadForPageView = {
          track: true,
          lastPageName: lastActivity,
          pageId,
          platform: 'Web',
          pageName: document.title,
          lastActivityName: lastActivity,
          pageURL: window.location.href,
          lastActivity,
          source: 'Web',
          name: user?.name || '',
          email: user?.email || '',
          loginMethod: user?.loginMethod || '',
          userId: user?.userId || '',
          phoneNumber: user?.phoneNumber || '',
          phoneCode: user?.phoneCode || '',
          state: user?.state || '',
          country: user?.countryName || '',
          subscriptionStatus: user?.subscription?.subscriptionStatus || '',
          planIdentifier: user?.subscriptionInfo?.identifier || '',
          planAmount: user?.subscriptionInfo?.totalAmount || '',
          city: user?.city || '',
          postalcode: user?.postalcode || '',
          isSubscribed: user?.isSubscribed || '',
          planId: user?.subscriptionInfo?.planId || '',
          paymentHandler: user?.subscriptionInfo?.paymentHandler || ''
        }

        // calling adjust page view event in settimeout because adjust is intialised little late
        if (contentData) {
          trackAdjustEvent(ADJUST_EVENTS.PAGE_VIEWED, payloadForPageView)
          trackCleverTapEvent(CLEVERTAP_EVENTS.PAGE_VIEWED, payloadForPageView)
          trackPageViewGa4('Page_View', payloadForPageView)
          trackFbPixel(pixelEvents.PAGE_VIEW, payloadForPageView)
          trackFbEvent(FB_EVENTS.PAGE_VIEW, payloadForPageView)
          trackPageViewEvent()
        }
      }, 1000)
    }
  }, [location.pathname, contentData])

  useEffect(() => {
    const salesforceBtn = document.querySelector('.helpButton')
    if (!salesforceBtn) return
    salesforceBtn.style.display = videoplayer ? 'none' : 'block'
  }, [videoplayer])

  useEffect(() => {
    const handleInitListener = (e) => {
      const actions = { setPopup, addToWatchlist, removeFromWatchlist }
      initializeListenner(e, history, actions, user)
    }
    document.addEventListener('click', handleInitListener, false)

    window.addEventListener('load', checkInternet)

    return () => {
      document.removeEventListener('click', handleInitListener, false)
      window.removeEventListener('load', checkInternet)
      clearInterval(checkInternetTimerId.current)
    }
  }, [])

  const checkInternet = () => {
    checkInternetTimerId.current = setInterval(() => {
      // if internet is there
      if (window.navigator.onLine) {
        // check if  disconnected is true
        if (isDisconnected) {
          setIsdisconnected(false)
        }
      }
      // if internet is not there
      else if (!window.navigator.onLine) {
        if (isDisconnected === false) {
          setIsdisconnected(true)
        }
      }
    }, 2000)
  }

  const { modules } = contentData
  const { moduleList } = layout

  useEffect(() => {
    if (user?.isSubscribed && location.pathname === '/subscribe') {
      navigate('/')
    }
  }, [location.pathname])

  const verifyuser = async (updatedUser) => {
    if (updatedUser?.id && updatedUser?.auth) {
      try {
        await checkToken()
      } catch (e) {
        if (e?.response?.status === 401) {
          logout()
          navigate('/')
        }
      }
    }
  }
  useEffect(() => {
    if (nextVideo?.id && location.pathname === '/') {
      playNextVideo(nextVideo.slideData, nextVideo.id).catch((err) => {})
      removeNextEpisodeId()
    }
  }, [location.pathname])

  const playNextVideo = async (slideData, videoId, nextEpisode = false) => {
    try {
      if (slideData?.gist?.contentType === 'SERIES' && !nextEpisode) {
        const seriesWatchHistoryRes = await fetchSeriesWatchHistory(
          slideData?.gist?.id
        )
        seriesWatchHistoryRef.current = seriesWatchHistoryRes?.data
      }
      const getVideoRes = await getVideoInfo(videoId)
      watchedDetailsRef.current = getVideoRes.data
      if (slideData?.gist?.contentType === 'VIDEO') {
        const getVideoRes = await getVideoInfo(slideData.gist.id)
        watchedDetailsRef.current = getVideoRes.data
      }

      const videoDetailsRes = await fetchVideoDetails(videoId)
      const videoData = videoDetailsRes?.data
      if (videoData) {
        const popupPayload = slideData
        popupPayload.videoId = videoId
        setPopup(VIDEOPLAYER, {
          contentData: popupPayload
        })
      } else {
        console.log('Failed to fetch video details')
      }
    } catch (err) {
      if (
        err?.response?.data?.errorCode === 'CANT_ACCESS_GEO_BLOCKED_BY_PLAN'
      ) {
        return setShowCommonPopup({
          heading: 'Access Denied',
          subheading:
            'You are accessing Hoichoi from a region that is not the same as your registered account. Please contact the support team to change your region.',
          ctaText: 'Contact our Support Team',
          onCtaClick: () => navigate('/user'),
          onCloseClick: () => setShowCommonPopup(false)
        })
      }

      setPopup(NOTIFIER, {
        message: err?.response?.data?.message || 'ERROR'
      })
    }
  }

  useEffect(() => {
    verifyuser(user).catch((err) => {
      console.log(err)
    })
  }, [location.pathname])

  // useLayoutEffect(() => history.replaceState('', null), [])
  // useEffect(() => {
  //   if (_location.state && history.state === '') {
  //     navigate(0)
  //   }
  // }, [_location.state])

  if (!layout || !contentData) {
    return (
      <>
        {isDisconnected && !closeInternetPop && (
          <NoInternet setCloseInternetPop={setCloseInternetPop} />
        )}
        <Loader center size={!mweb && 'md'} />
      </>
    )
  }

  if (tokenVerificationPending && graminUser) {
    return (
      <>
        <Loader center size="sm" />
      </>
    )
  }

  // if (requireAdmin && !sessionStorage.getItem('adminLogin')) {
  //   return <AdminLogin setRequireAdmin={setRequireAdmin} />
  // }

  return (
    <div onContextMenu={() => {}}>
      {/* <Partytown
        debug
        forward={['dataLayer.push', 'fbq']}
        resolveUrl={function (url, location, type) {
          if (type === 'script') {
            const proxyUrl = new URL(self.location.origin + '/proxy')
            proxyUrl.searchParams.append('url', url.href)
            return proxyUrl
          }
          return url
        }}
      /> */}
      <Toaster position="bottom-right" richColors />
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>

      {/* Other routes */}

      <div>
        {browserName === '' && <InstallerBanner />}
        {isDisconnected && !closeInternetPop && (
          <NoInternet setCloseInternetPop={setCloseInternetPop} />
        )}
        {showAddPhoneScreen && (
          <AddPhone
            userId={user.id}
            setShowAddPhoneScreen={setShowAddPhoneScreen}
          />
        )}
        <MetaTags
          contentData={{
            path,
            ...contentData,
            domainName: config.domainName,
            location: location.pathname
          }}
        />
        {showTopBanner && (
          <TopBanner
            text={bannerDetails.text}
            type={bannerDetails.type}
            to={bannerDetails.to}
            centerCheck={bannerDetails.centerCheck}
            onCloseClick={(e) => {
              setTopBanner(false)
            }}
            mweb={mweb}
          />
        )}
        {modules && moduleList && (
          <LazyLoadModules
            components={contentData.modules}
            moduleList={layout.moduleList}
            pathname={location.pathname}
            mweb={mweb}
            totalModuleCount={contentData.moduleCount}
            topBanner={showTopBanner}
          />
        )}
        {/* {mweb && <BottomMenuBar />}
        {mweb && <BottomMargin />} */}
        {notifier && (
          <Notifier
            message={notifier.message}
            show={notifier}
            onDismissed={() => {
              setPopup(NOTIFIER)
            }}
          />
        )}
        {videoplayer && <VideoPlayer contentData={videoplayer.contentData} />}

        {commonpopup ||
          (showCommonPopup && <CommonPopup {...showCommonPopup} />)}

        {login && LoginComponent && (
          <LoginComponent
            mweb={mweb}
            openLogin={login}
            onLoginClose={() => setPopup('login')}
          />
        )}

        <FloatingButton user={user} />
      </div>
    </div>
  )
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const path = ownProps.location.pathname
      return {
        layout: state.page[`MOD-${path}`] || '',
        contentData: state.page[`CON-${path}`] || '',
        user: state.user,
        mweb: state.page.mweb,
        isTablet: state.page.isTablet,
        notifier: state.popups?.notifier,
        videoplayer: state.popups?.videoplayer,
        commonpopup: state.popups?.commonpopup,
        login: state.popups?.login,
        nextVideo: state?.nextVideo,
        navSettings: state?.pageSettings?.navigation,
        pageSettings: state?.pageSettings,
        path,
        graminUser: state?.graminUser?.data
      }
    },

    {
      getCachePageData,
      setMWeb,
      getWatchlist,
      setPopup,
      addToWatchlist,
      removeFromWatchlist,
      addToUser,
      getPageSettingsData,
      logout,
      fetchHistory,
      removeNextEpisodeId,
      updateUserDetailsWithoutApi,
      addgraminUserDetails
    }
  )(App)
)
